import {
  CircleRingIcon,
  SearchIcon,
  SearchIssueIcon,
  StatusCircleCheckmarkIcon,
  WarningIcon,
} from "@fluentui/react-icons-mdl2";
import { registerIcons } from "@fluentui/style-utilities";
import {
  faAngleLeft,
  faBars,
  faBuilding,
  faCalendarAlt,
  faCaretSquareUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faEnvelope,
  faExchange,
  faEye,
  faEyeSlash,
  faFile,
  faFileExcel,
  faFilePdf,
  faInfoCircle,
  faKey,
  faLock,
  faLongArrowDown,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowUp,
  faMegaphone,
  faPen,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faSearch,
  faSignOut,
  faSortDown,
  faSortUp,
  faSync,
  faTimes,
  faTimesCircle,
  faTimesHexagon,
  faToggleOff,
  faToggleOn,
  faTrashAlt,
  faUnlock,
  faUser,
  faUserFriends,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

registerIcons({
  icons: {
    circleRing: <CircleRingIcon />,
    statusCircleCheckmark: <StatusCircleCheckmarkIcon />,
    warning: <WarningIcon />,
    // Das Icon RedEye wird fuer den Passwort Reveal Button verwendet und hier
    // mit einem entsprechenden Font Awesome Icon ueberschrieben
    RedEye: <FontAwesomeIcon icon={faEye} />,
    // Das Icon Hide wird fuer den Passwort Reveal Button verwendet und hier
    // mit einem entsprechenden Font Awesome Icon ueberschrieben
    Hide: <FontAwesomeIcon icon={faEyeSlash} />,
    // Das Icon ChevronDown wird z.B. fuer den Dropdown verwendet und hier
    // mit einem entsprechenden Font Awesome Icon ueberschrieben
    ChevronDown: <FontAwesomeIcon icon={faChevronDown} />,
    faCaretSquareUp: <FontAwesomeIcon icon={faCaretSquareUp} />,
    faCaretSquareUpTransparent: (
      <FontAwesomeIcon icon={faCaretSquareUp} color="transparent" />
    ),
    faSignOut: <FontAwesomeIcon icon={faSignOut} />,
    faAngleLeft: <FontAwesomeIcon icon={faAngleLeft} />,
    faSearch: <FontAwesomeIcon icon={faSearch} />,
    faBuilding: <FontAwesomeIcon icon={faBuilding} />,
    faUser: <FontAwesomeIcon icon={faUser} />,
    faPlusSquare: <FontAwesomeIcon icon={faPlusSquare} />,
    faTimesHexagon: <FontAwesomeIcon icon={faTimesHexagon} />,
    faToggleOff: <FontAwesomeIcon icon={faToggleOff} />,
    faToggleOn: <FontAwesomeIcon icon={faToggleOn} />,
    faKey: <FontAwesomeIcon icon={faKey} />,
    sortdown: <FontAwesomeIcon icon={faSortDown} />,
    sortup: <FontAwesomeIcon icon={faSortUp} />,
    faLock: <FontAwesomeIcon icon={faLock} />,
    faUnlock: <FontAwesomeIcon icon={faUnlock} />,
    faEye: <FontAwesomeIcon icon={faEye} />,
    faPen: <FontAwesomeIcon icon={faPen} />,
    faSync: <FontAwesomeIcon icon={faSync} />,
    faTrashAlt: <FontAwesomeIcon icon={faTrashAlt} />,
    // Die Icons info, clear, errorbadge und completed werden fuer die MessageBar
    // verwendet und hier mit einem entsprechenden Font Awesome Icon
    // ueberschrieben
    info: <FontAwesomeIcon icon={faInfoCircle} />,
    clear: <FontAwesomeIcon icon={faTimes} />,
    errorbadge: <FontAwesomeIcon icon={faTimesCircle} />,
    completed: <FontAwesomeIcon icon={faCheckCircle} />,
    // Die Icons calendar, up und down werden fuer den DatePicker verwendet und
    // hier mit entpsrechenden Font Awesome Icons ueberschrieben
    calendar: <FontAwesomeIcon icon={faCalendarAlt} />,
    up: <FontAwesomeIcon icon={faLongArrowUp} />,
    down: <FontAwesomeIcon icon={faLongArrowDown} />,
    left: <FontAwesomeIcon icon={faLongArrowLeft} />,
    right: <FontAwesomeIcon icon={faLongArrowRight} />,
    faFileExcel: <FontAwesomeIcon icon={faFileExcel} />,
    faFilePdf: <FontAwesomeIcon icon={faFilePdf} />,
    faFile: <FontAwesomeIcon icon={faFile} />,
    chevronUp: <FontAwesomeIcon icon={faChevronUp} />,
    // Das Icon checkmark wird z.B. fuer die Checkbox Component verwendet und
    // hier mit einem entsprechenden Font Awesome Icon ueberschrieben
    checkmark: <FontAwesomeIcon icon={faCheck} />,
    bars: <FontAwesomeIcon icon={faBars} />,
    faUserFriends: <FontAwesomeIcon icon={faUserFriends} />,
    questionCircle: <FontAwesomeIcon icon={faQuestionCircle} />,
    faExchange: <FontAwesomeIcon icon={faExchange} />,
    faEnvelope: <FontAwesomeIcon icon={faEnvelope} />,
    faPlus: <FontAwesomeIcon icon={faPlus} />,
    faRedo: <FontAwesomeIcon icon={faRedo} />,
    search: <SearchIcon />,
    searchIssue: <SearchIssueIcon />,
    faMegaphone: <FontAwesomeIcon icon={faMegaphone} />,
  },
});
