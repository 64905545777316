import { IPalette, PartialTheme } from "@fluentui/react";

const palette = {
  themePrimary: "#004e9e",
  themeLighterAlt: "#f1f6fb",
  themeLighter: "#c9dcef",
  themeLight: "#9ec0e2",
  themeTertiary: "#4f8ac5",
  themeSecondary: "#145faa",
  themeDarkAlt: "#00478e",
  themeDark: "#003c78",
  themeDarker: "#002c59",
  neutralLighterAlt: "#faf9f8",
  neutralLighter: "#f3f2f1",
  neutralLight: "#edebe9",
  neutralQuaternaryAlt: "#e1dfdd",
  neutralQuaternary: "#d0d0d0",
  neutralTertiaryAlt: "#c8c6c4",
  neutralTertiary: "#c6c6c6",
  neutralSecondary: "#8e8e8e",
  neutralPrimaryAlt: "#595959",
  neutralPrimary: "#424242",
  neutralDark: "#323232",
  black: "#252525",
  white: "#ffffff",
  errorBackgroundDisabled: "#bd3f45",
  errorBackgroundHover: "#982329",
  errorBackgroundPressed: "#8b2025",

  red: "#dc3545",
  redLight: "#fbeaec",
  redDark: "#dc3545",
  yellow: "#ffc107",
  yellowLight: "#fff8e6",
  yellowDark: "#ffc10",
  orange: "#fd7e14",
  green: "#28A745",
};

export type AppPalette = typeof palette & IPalette;

const theme: PartialTheme = {
  defaultFontStyle: {
    fontFamily: "Open Sans, Sans-Serif",
    fontSize: 16,
    fontWeight: 300,
    lineHeight: "1.5em",
  },
  fonts: {
    small: {
      fontSize: "0.75rem",
    },
    medium: {
      fontSize: "1rem",
    },
    large: {
      fontSize: "1.375rem",
    },
    xLarge: {
      fontSize: "1.5625rem",
    },
    xxLarge: {
      fontSize: "1.875rem",
    },
  },
  palette,
  semanticColors: {
    listText: palette.neutralDark,
  },
};

export default theme;
