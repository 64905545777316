import {
  FontIcon,
  Icon,
  Link as FluentUILink,
  mergeStyleSets,
  Text,
  Theme,
  useTheme,
} from "@fluentui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../apiContext";
import { useIsArticleFormDirty } from "../articleFormContext";
import { useAuthDispatch, useAuthState } from "../authContext";
import { AuftragStatus } from "../dto/AuftragStatusDTO";
import { useFeatureDiscovery } from "../featureDiscoveryContext";
import { usePromptBefore } from "../hooks/usePromptBefore";
import logo from "../img/logo.svg";
import { Action } from "../models/User";
import { useOrderState } from "../orderStateContext";
import BorderBox from "./BorderBox";
import CircularProgressIndicator, {
  CircularProgressIndicatorMode,
} from "./CircularProgressIndicator";
import Container from "./Container";
import Info from "./Info";
import InfoBar from "./InfoBar";
import NavLink from "./NavLink";
import { TeachingBubbleTrigger } from "./TeachingBubbleTrigger";
import ValidationBadge, { ValidationBadgeMode } from "./ValidationBadge";

function getClassNames(theme: Theme, isMenuVisible: boolean) {
  return mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "1.25rem",
      marginTop: "3rem",
    },
    topRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "1.75rem",
    },
    navigationBar: {
      backgroundColor: theme.palette.white,
      display: "flex",
      zIndex: 2,

      "@media(max-width: 1136px)": {
        alignSelf: "flex-start",
        boxShadow: "0 10px 27px rgba(0, 0, 0, 0.05)",
        display: isMenuVisible ? "block" : "none",
        opacity: isMenuVisible ? 1 : 0,
        padding: "1rem 0",
        width: "100%",
      },
    },
    navigationToggle: {
      display: "none",
      zIndex: 1,

      "@media(max-width: 1136px)": {
        display: isMenuVisible ? "inline-block" : "none",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    navigationIcon: {
      display: "none",
      cursor: "pointer",

      "@media(max-width: 1136px)": {
        display: "inline-block",
        position: "relative",
        textAlign: "center",
        verticalAlign: "middle",
      },
    },
    navigationIconBadge: {
      display: "none",

      "@media(max-width: 1136px)": {
        display: isMenuVisible ? "none" : "inline-block",
        position: "absolute",
        right: "-1.25rem",
        top: "-0.675rem",
      },
    },
    navigationLink: {
      listStyleType: "none",
      margin: "0 0.75rem",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",

      "@media(max-width: 1136px)": {
        margin: "0.5rem 0.75rem",
        display: "block",
      },
    },
    separator: {
      borderRight: `1px solid ${theme.semanticColors.buttonBorder}`,

      "@media(max-width: 1136px)": {
        display: "block",
        borderRight: "none",
        borderBottom: `1px solid ${theme.semanticColors.buttonBorder}`,
        margin: "0 0.75rem",
      },
    },
    logo: {
      height: "1.75rem",

      "@media(max-width: 768px)": {
        height: "1.25rem",
      },
    },
    profile: {
      alignSelf: "flex-end",
      marginTop: "1.25rem",
    },
    icon: {
      color: theme.palette.themePrimary,
      fontSize: "1.25rem",
    },
    signOut: {
      display: "none",

      "@media(max-width: 1136px)": {
        display: "contents",
        marginRight: "0.75rem",
      },
    },
  });
}

type HeaderProps = {
  isNavigationBlocked?: boolean;
};

export default function Header(props: HeaderProps) {
  const [isNavBarVisible, setIsNavBarVisible] = React.useState(false);
  const navigate = useNavigate();
  const authDispatch = useAuthDispatch();
  const { t } = useTranslation();
  const api = useApi();
  const auth = useAuthState();
  const theme = useTheme();
  const location = useLocation();
  const { hasDiscoveredFeature } = useFeatureDiscovery();
  const styles = getClassNames(theme, isNavBarVisible);
  const orderState = useOrderState();
  const isDirty = useIsArticleFormDirty();
  const isBlocked = props.isNavigationBlocked || isDirty;
  usePromptBefore({
    isBlocked,
    onProceed: (location) => {
      if (location.pathname === "/de/logout") {
        api?.postLogout();
        authDispatch({ type: "FETCH_SUCCESS", payload: null });
      }
    },
  });

  const orderBadgeAriaLabel = useMemo(
    () =>
      orderState.data?.valid
        ? t("header.myOrderAriaLabel", {
            count: orderState.data?.anzahlPositionen,
          })
        : t("header.myOrderAriaLabelInvalid"),
    [orderState.data?.anzahlPositionen, orderState.data?.valid, t]
  );

  function handleLogout() {
    navigate("/de/logout");
  }

  function hasPermission(...requiredAction: Action[]) {
    const { profile } = auth;

    if (!profile) {
      return false;
    }

    return (
      requiredAction.length < 1 ||
      requiredAction.some((action) => profile.allowedActions.includes(action))
    );
  }

  function renderOrderBadge() {
    if (orderState.data?.status === AuftragStatus.IN_BEARBEITUNG) {
      return (
        <div
          style={{
            display: "inline-block",
            padding: "0 0.3em",
            marginInlineStart: "0.5em",
          }}
        >
          <CircularProgressIndicator
            size={16}
            progress={orderState.data?.verarbeitung?.fortschritt ?? 0}
            isLabelVisible={false}
          />
        </div>
      );
    }

    if (orderState.isSuccess) {
      return (
        <div
          style={{
            display: "inline-block",
            padding: "0 0.3em",
            marginInlineStart: "0.5em",
          }}
        >
          <CircularProgressIndicator
            size={16}
            progress={1.0}
            isLabelVisible={false}
            mode={CircularProgressIndicatorMode.SUCCESS}
          />
        </div>
      );
    }

    return (
      <ValidationBadge
        mode={
          orderState.data?.valid === false
            ? ValidationBadgeMode.ERROR
            : undefined
        }
        ariaLabel={orderBadgeAriaLabel}
      >
        {orderState.data?.valid === false
          ? "!"
          : orderState.data?.anzahlPositionen}
      </ValidationBadge>
    );
  }

  const toggleNavBar = React.useCallback(() => {
    setIsNavBarVisible((prevValue) => !prevValue);
  }, []);

  return (
    <Container>
      <header className={styles.container}>
        <div className={styles.topRow}>
          <Link to="/" onClick={toggleNavBar}>
            <img
              className={styles.logo}
              src={logo}
              alt="Informationsstelle für Arzneispezialitäten IFA GmbH"
            />
          </Link>
          <ul className={styles.navigationBar}>
            {hasPermission() && (
              <li className={styles.navigationLink}>
                <NavLink exact to="/de" onClick={toggleNavBar}>
                  {t("header.overview")}
                </NavLink>
              </li>
            )}
            {hasPermission(
              Action.VIEW_ARTICLES,
              Action.VIEW_OTHER_ARTICLES
            ) && (
              <li className={styles.navigationLink}>
                <NavLink to="/de/artikel" onClick={toggleNavBar}>
                  {t("header.article")}
                </NavLink>
              </li>
            )}
            {hasPermission(Action.VIEW_NOTIFICATIONS) && (
              <li className={styles.navigationLink}>
                <NavLink to="/de/benachrichtigungen" onClick={toggleNavBar}>
                  {t("header.notifications")}
                </NavLink>
              </li>
            )}
            {hasPermission(Action.MANAGE_USERS) && (
              <li className={styles.navigationLink}>
                <NavLink to="/de/benutzerverwaltung" onClick={toggleNavBar}>
                  {t("header.usermanagement")}
                </NavLink>
              </li>
            )}
            {hasPermission(Action.VIEW_USAGE_STATISTICS) && (
              <li className={styles.navigationLink}>
                <NavLink to="/de/statistik" onClick={toggleNavBar}>
                  {t("header.statistics")}
                </NavLink>
              </li>
            )}
            {hasPermission(Action.VIEW_ORDERS) && (
              <li
                data-teaching-id="MyOrderMenuItem"
                className={styles.navigationLink}
              >
                {
                  // TeachingBubble anzeigen, wenn
                  // - der Benutzer schon einmal eine Auftragsposition angelegt hat
                  // - er nicht ohnehin schon auf der Seite für diesen Link ist
                  hasDiscoveredFeature("AuftragPositionAngelegt") &&
                    location.pathname !== "/de/mein-auftrag" && (
                      <TeachingBubbleTrigger teachingId="MyOrderMenuItem" />
                    )
                }
                <NavLink to="/de/mein-auftrag" onClick={toggleNavBar}>
                  {t("header.myOrder")}
                </NavLink>
                {renderOrderBadge()}
              </li>
            )}
            {!hasPermission() && (
              <li className={styles.navigationLink}>
                <FluentUILink
                  href="https://www.ifaffm.de"
                  onClick={toggleNavBar}
                >
                  {t("header.toWebsite")}
                </FluentUILink>
              </li>
            )}
            {hasPermission() && (
              <>
                <div className={styles.separator} />

                <li className={styles.navigationLink}>
                  <FluentUILink
                    onClick={handleLogout}
                    title={t("action.signOut")}
                  >
                    <div className={styles.signOut}>{t("header.signOut")}</div>
                    <FontIcon style={{ marginLeft: 8 }} iconName="faSignOut" />
                  </FluentUILink>
                </li>
              </>
            )}
          </ul>
          <div className={styles.navigationToggle} onClick={toggleNavBar} />
          <div className={styles.navigationIcon} onClick={toggleNavBar}>
            <Icon iconName={"bars"} className={styles.icon} />
            <div className={styles.navigationIconBadge}>
              {hasPermission(Action.VIEW_ORDERS) && renderOrderBadge()}
            </div>
          </div>
        </div>
        {auth.profile && (
          <div className={styles.profile}>
            <BorderBox>
              <InfoBar>
                <Info>
                  <Icon iconName="faUser" />
                  <Text>{auth.profile.benutzerkennung}</Text>
                </Info>
                <Info>
                  <Icon iconName="faBuilding" />
                  <Text>
                    {t("header.profile.customer", {
                      kundennr: auth.profile.kundennr,
                      firmenname: auth.profile.firmenname,
                    })}
                  </Text>
                </Info>
                {auth.profile.allowedActions.includes(
                  Action.EDIT_OWN_PROFILE
                ) && (
                  <Info>
                    <Link to={"/de/profil"}>
                      <Icon iconName="faPen" className={styles.icon} />
                    </Link>
                  </Info>
                )}
              </InfoBar>
            </BorderBox>
          </div>
        )}
      </header>
    </Container>
  );
}
