import React from "react";

import Api from "./api";
import { useApi } from "./apiContext";
import { useAuthState } from "./authContext";
import ArtikelAttributMetaDataDTO from "./dto/ArtikelAttributMetaDataDTO";
import User, { Action as AllowedAction } from "./models/User";

type Action =
  | { type: "FETCH_INIT" }
  | { type: "FETCH_SUCCESS"; payload: ArtikelAttributMetaDataDTO }
  | { type: "FETCH_FAILURE" }
  | { type: "RESET" };
type State = {
  isLoading: boolean;
  isError: boolean;
  data?: ArtikelAttributMetaDataDTO;
};
type ArticleMetaDataProviderProps = { children: React.ReactNode };

const ArticleMetaDataContext = React.createContext<State | undefined>(
  undefined
);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true, isError: false };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case "FETCH_FAILURE":
      return { ...state, isLoading: false, isError: true };
    case "RESET":
      return { ...initialState };
  }
}

const initialState: State = {
  isLoading: false,
  isError: false,
};

function ArticleMetaDataProvider({ children }: ArticleMetaDataProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const api = useApi();
  const auth = useAuthState();
  const previousProfile = React.useRef<User | null | undefined>();

  React.useEffect(() => {
    if (previousProfile.current && !auth.profile) {
      dispatch({ type: "RESET" });
    }

    previousProfile.current = auth.profile;
  }, [auth]);

  React.useEffect(() => {
    let didCancel = false;

    async function fetchData(api: Api) {
      dispatch({ type: "FETCH_INIT" });

      const response = await api.getArticlesMeta();

      if (didCancel) {
        return;
      }

      if (response.type === "Success") {
        dispatch({ type: "FETCH_SUCCESS", payload: response.data });
      } else {
        // TODO
      }
    }

    if (
      !api ||
      !auth.profile ||
      !auth.profile.allowedActions.includes(AllowedAction.VIEW_ARTICLES)
    ) {
      return;
    }

    fetchData(api);

    return () => {
      didCancel = true;
    };
  }, [api, auth]);

  return (
    <ArticleMetaDataContext.Provider value={state}>
      {children}
    </ArticleMetaDataContext.Provider>
  );
}

function useArticleMetaData() {
  const context = React.useContext(ArticleMetaDataContext);

  if (context === undefined) {
    throw new Error(
      "useArticleMetaData must be used within a ArticleMetaDataContext"
    );
  }

  return context;
}

function useProofDocumentMetaData(nachweisTyp: string) {
  const context = React.useContext(ArticleMetaDataContext);

  if (context === undefined) {
    throw new Error(
      "useProofDocumentMetaData must be used within a ArticleMetaDataContext"
    );
  }

  const proofDocumentTypes = context.data?.schluesselObjekte.find(
    (val) => val.name === "NWTYP"
  );

  if (!proofDocumentTypes) {
    console.warn("Missing proof document types from catalog data");
  }

  return proofDocumentTypes?.werte.find((value) => value.wert === nachweisTyp);
}

export {
  ArticleMetaDataProvider,
  useArticleMetaData,
  useProofDocumentMetaData,
};
