import { Spinner } from "@fluentui/react";
import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import ErrorScreen from "screens/ErrorScreen";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import RootProvider from "./components/RootProvider";
import { Action } from "./models/User";
const AcceptInvitation = lazy(() => import("./screens/AcceptInvitation"));
const Articles = lazy(() => import("./screens/Articles"));
const Allocations = lazy(() => import("./screens/Allocations"));
const CreateUser = lazy(() => import("./screens/CreateUser"));
const Home = lazy(() => import("./screens/Home"));
const InviteUsers = lazy(() => import("./screens/InviteUsers"));
const MyOrder = lazy(() => import("./screens/MyOrder"));
const NoMatch = lazy(() => import("./screens/NoMatch"));
const Notifications = lazy(() => import("./screens/Notifications"));
const PriceCalculator = lazy(() => import("./screens/PriceCalculator"));
const Profile = lazy(() => import("./screens/Profile"));
const RequestResetPassword = lazy(
  () => import("./screens/RequestResetPassword")
);
const SetPassword = lazy(() => import("./screens/SetPassword"));
const SignUp = lazy(() => import("./screens/SignUp"));
const SignUpRequest = lazy(() => import("./screens/SignUpRequest"));
const Usermanagement = lazy(() => import("./screens/Usermanagement"));
const UserScreen = lazy(() => import("./screens/UserScreen"));
const UsageStatistics = lazy(() => import("screens/UsageStatistics"));
const ConfirmEmail = lazy(() => import("screens/ConfirmEmail"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <RootProvider>
          <Suspense
            fallback={
              <Layout>
                <Spinner />
              </Layout>
            }
          >
            <Outlet />
          </Suspense>
        </RootProvider>
      }
    >
      <Route path="" element={<Navigate to="/de" replace />} />
      <Route path="de" element={<Outlet />} errorElement={<ErrorScreen />}>
        <Route path="" element={<Home />} />
        <Route
          path="benutzerverwaltung"
          element={
            <PrivateRoute requiredAction={[Action.MANAGE_USERS]}>
              <Usermanagement />
            </PrivateRoute>
          }
        />
        <Route
          path="benachrichtigungen"
          element={
            <PrivateRoute requiredAction={[Action.VIEW_NOTIFICATIONS]}>
              <Notifications />
            </PrivateRoute>
          }
        />
        <Route
          path="statistik"
          element={
            <PrivateRoute requiredAction={[Action.VIEW_USAGE_STATISTICS]}>
              <UsageStatistics />
            </PrivateRoute>
          }
        />
        <Route
          path="benutzer/:id"
          element={
            <PrivateRoute requiredAction={[Action.MANAGE_USERS]}>
              <UserScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="benutzer-erstellen"
          element={
            <PrivateRoute requiredAction={[Action.MANAGE_USERS]}>
              <CreateUser />
            </PrivateRoute>
          }
        />
        <Route
          path="benutzer-einladen"
          element={
            <PrivateRoute requiredAction={[Action.MANAGE_OTHER_CUSTOMERS]}>
              <InviteUsers />
            </PrivateRoute>
          }
        />
        <Route
          path="profil"
          element={
            <PrivateRoute requiredAction={[Action.EDIT_OWN_PROFILE]}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="artikel/*"
          element={
            <PrivateRoute
              requiredAction={[
                Action.VIEW_ARTICLES,
                Action.VIEW_OTHER_ARTICLES,
              ]}
            >
              <Articles />
            </PrivateRoute>
          }
        />
        <Route
          path="zuteilung/*"
          element={
            <PrivateRoute
              requiredAction={[
                Action.VIEW_ARTICLES,
                Action.VIEW_OTHER_ARTICLES,
              ]}
            >
              <Allocations />
            </PrivateRoute>
          }
        />
        <Route
          path="mein-auftrag"
          element={
            <PrivateRoute requiredAction={[Action.VIEW_ORDERS]}>
              <MyOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="preisrechner"
          element={
            <PrivateRoute>
              <PriceCalculator />
            </PrivateRoute>
          }
        />
        <Route
          path="registrierungs-anfrage/:id"
          element={
            <PrivateRoute>
              <SignUpRequest />
            </PrivateRoute>
          }
        />
        {/* Redirect der alten Login URL (PORTAL-623) */}
        <Route path="login" element={<Navigate to="/de" replace />} />
        <Route path="logout" element={<Navigate to="/de" replace />} />
        <Route path="registrierung" element={<SignUp />} />
        <Route path="initiales-passwort/:token" element={<SetPassword />} />
        <Route path="passwort-zuruecksetzen/:token" element={<SetPassword />} />
        <Route
          path="einladung-annehmen/:token"
          element={<AcceptInvitation />}
        />
        <Route path="email-bestaetigen/:token" element={<ConfirmEmail />} />
        <Route
          path="passwort-zuruecksetzen"
          element={<RequestResetPassword />}
        />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Route>
  )
);

export default function App() {
  return <RouterProvider router={router} />;
}
