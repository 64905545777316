import {
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import React from "react";
import Card from "./Card";

type CardFormProps = {
  children: React.ReactNode;
  action?: React.ReactNode;
  heading: string;
  subHeading?: string;
  subSubHeading?: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  submitLabel: string;
  disabled: boolean;
  loading?: boolean;
};

function getClassNames() {
  return mergeStyleSets({
    formWrapper: {
      alignSelf: "stretch",
      display: "flex",
      justifyContent: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flexBasis: 340,
      flexShrink: 1,
    },
    submitButton: {
      alignSelf: "flex-end",
      marginTop: "1rem",
    },
  });
}

export default function CardForm({
  children,
  onSubmit,
  submitLabel,
  disabled,
  loading = false,
  action,
  ...props
}: CardFormProps) {
  const styles = getClassNames();

  return (
    <Card {...props}>
      <div className={styles.formWrapper}>
        <form className={styles.form} onSubmit={onSubmit}>
          {children}
          <PrimaryButton
            type="submit"
            disabled={disabled || loading}
            className={styles.submitButton}
            onRenderIcon={() =>
              loading ? (
                <Spinner
                  size={SpinnerSize.small}
                  styles={{ root: { marginRight: "0.25rem" } }}
                />
              ) : null
            }
          >
            {submitLabel}
          </PrimaryButton>
        </form>
      </div>
      {action}
    </Card>
  );
}
