import { mergeStyleSets, Theme, useTheme } from "@fluentui/react";
import { AriaAttributes } from "react";

export enum ValidationBadgeMode {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  HIGHLIGHT = "highlight",
  MISSING = "missing",
}

type ValidationBadgeProps = {
  mode?: ValidationBadgeMode;
  children?: React.ReactNode;
  overlapping?: boolean;
  ariaLabel?: AriaAttributes["aria-label"];
};

function getClassNames(theme: Theme, overlapping?: boolean) {
  return mergeStyleSets({
    badge: {
      borderRadius: "50%",
      display: "inline-block",
      minWidth: "1.5em",
      padding: "0 0.3em",
      border: "1px solid " + theme.palette.white,
      marginInlineStart: "0.5em",
      marginInlineEnd: overlapping ? "-0.8em" : "0.5em",
      textAlign: "center",
      flexGrow: 0,
      flexShrink: 0,
      fontWeight: "bold",
      color: theme.palette.themePrimary,
      backgroundColor: theme.palette.themeLighterAlt,
      ":first-child": {
        marginInlineStart: "0em",
      },
      ":last-child": {
        marginInlineEnd: 0,
      },
    },
    [ValidationBadgeMode.ERROR]: {
      color: `${theme.palette.white} !important`,
      backgroundColor: `${theme.palette.red} !important`,
      zIndex: 100,
    },
    [ValidationBadgeMode.WARNING]: {
      color: `${theme.palette.white} !important`,
      backgroundColor: `${theme.palette.yellow} !important`,
      zIndex: 101,
    },
    [ValidationBadgeMode.SUCCESS]: {
      color: `${theme.palette.white} !important`,
      backgroundColor: `${theme.palette.green} !important`,
      zIndex: 101,
    },
    [ValidationBadgeMode.HIGHLIGHT]: {
      color: `${theme.palette.themeDark} !important`,
      backgroundColor: `${theme.palette.themeLighterAlt} !important`,
      zIndex: 101,
    },
    [ValidationBadgeMode.MISSING]: {
      color: `${theme.palette.white} !important`,
      backgroundColor: `${theme.palette.orange} !important`,
      zIndex: 101,
    },
  });
}

export default function ValidationBadge({
  mode,
  children,
  overlapping,
  ariaLabel,
}: ValidationBadgeProps) {
  const styles = getClassNames(useTheme(), overlapping);

  return (
    <div
      className={`${styles.badge} ${mode ? styles[mode] : ""}`}
      aria-label={ariaLabel}
      role="status"
    >
      {children}
    </div>
  );
}
