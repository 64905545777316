import React from "react";
import { Theme, useTheme, DefaultButton, IButtonStyles } from "@fluentui/react";

type DestructiveButtonProps = {
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  primary?: boolean;
  type?: string;
  disabled?: boolean;
};

const buttonStyles = (
  theme: Theme,
  primary?: boolean
): Partial<IButtonStyles> => {
  // @ts-ignore
  const errorBackgroundHover = theme.palette.errorBackgroundHover;

  // @ts-ignore
  const errorBackgroundPressed = theme.palette.errorBackgroundPressed;

  // @ts-ignore
  const errorBackgroundDisabled = theme.palette.errorBackgroundDisabled;

  return primary
    ? {
        root: {
          fontSize: theme.fonts.medium.fontSize,
          background: theme.palette.red,
          color: theme.semanticColors.bodyBackground,
          borderColor: theme.palette.red,
        },
        rootHovered: {
          background: errorBackgroundHover,
          color: theme.semanticColors.bodyBackground,
          borderColor: errorBackgroundHover,
        },
        rootPressed: {
          background: errorBackgroundPressed,
          color: theme.semanticColors.bodyBackground,
          borderColor: errorBackgroundPressed,
        },
        rootDisabled: {
          background: errorBackgroundDisabled,
        },
      }
    : {
        root: {
          fontSize: theme.fonts.medium.fontSize,
          color: theme.palette.red,
          borderColor: theme.palette.red,
        },
        rootHovered: {
          color: theme.palette.red,
        },
        rootPressed: {
          color: theme.palette.red,
        },
      };
};

export default function DestructiveButton({
  title,
  onClick,
  primary,
  type,
  disabled,
}: DestructiveButtonProps) {
  const theme = useTheme();

  return (
    <DefaultButton
      styles={buttonStyles(theme, primary)}
      type={type}
      disabled={disabled}
      text={title}
      onClick={onClick}
    />
  );
}
